@font-face {
  font-family: BebasNeue;
  src: url(./fonts/BebasNeue-Regular.ttf);
}

@font-face {
  font-family: 'Open Sans';
  src: url(./fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSans;
  src: url(./fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'Open Sans';
  src: url(./fonts/OpenSans-Light.ttf);
  font-weight: 200;
}

@font-face {
  font-family: OpenSans;
  src: url(./fonts/OpenSans-Light.ttf);
  font-weight: 200;
}

@font-face {
  font-family: 'Open Sans';
  src: url(./fonts/OpenSans-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: OpenSans;
  src: url(./fonts/OpenSans-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url(./fonts/OpenSans-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: OpenSans;
  src: url(./fonts/OpenSans-Bold.ttf);
  font-weight: 700;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

body.fontLoaded {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#root {
  background-color: #092143;
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  font-family: Georgia, Times, 'Times New Roman', serif;
  line-height: 1.5em;
}
