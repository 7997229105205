@import '../../styles/colors.scss';

* focus {
  outline: 0;
}

#new-survey-wrapper {
  width: 998px;
  height: 100%;

  .blockWrapper {
    display: flex;
    // margin-top: 30px;
    // margin-bottom: 30px;
    align-items: center;
  }

  .checkboxWrapper {
    flex: none;
    margin-top: 13px;
    margin-right: 14px;
    display: contents;
  }
  .labelWrapper {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 1.33;
    color: $greyish-brown;
    padding-left: 10px;
  }

  .labelModule {
    display: flex;
    padding-left: 10px;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 3.57;
    letter-spacing: normal;
    color: $blood-orange;
  }

  .container {
    width: 600px;
    margin: 50px auto;
  }
  .progressbar {
    //counter-reset: step;
  }
  .progressbar li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
  }
  .progressbar li:before {
    width: 30px;
    height: 30px;
    content: ''; // counter(step);
    //counter-increment: step;
    border: 7px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
  }
  .progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 15px;
    left: -50%;
    z-index: -1;
  }
  .progressbar li:first-child:after {
    content: none;
  }
  .progressbar li.active {
    color: $blood-orange;
  }
  .progressbar li.active:before {
    border-color: $blood-orange;
  }
  .progressbar li.active + li:after {
    background-color: #cacaca;
  }
  #page-title {
    padding-left: 57px;
    padding-top: 42px;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #000000;
  }

  #steps-title {
    padding-top: 14px;
    display: flex;
    justify-content: center;
    font-family: OpenSans;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $blood-orange;
  }

  #block-title {
    padding-top: 51px;
    font-family: OpenSans;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $greyish-brown;
  }

  #block-wrapper {
    padding-left: 56px;

    .select-numbers-1 {
      display: inline-block;
      width: 391px;
      height: 38px;
      border-radius: 5px;
      background-color: #ebebeb;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    #block-line {
      display: flex;
      justify-content: left;
      margin-left: -0%;
      width: 880px;
      height: 0px;
      border: solid 1px $warm-grey;
    }

    .date-range-block {
      #inner-block {
        padding-left: 300px;
      }
    }

    #inner-block {
      display: flex;
      flex-direction: column;

      .inner-item {
        font-family: OpenSans;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 3.57;
        letter-spacing: normal;
        margin-left: 58px;
      }
    }

    #inner-block-line {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .custom-select {
        margin-left: 10px;
        align-self: center;
      }

      .label-numbers {
        width: 174px;
        height: 37px;
        font-family: OpenSans;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 3.57;
        letter-spacing: normal;
        color: $greyish-brown;
        padding-left: 10px;
      }
    }

    #bottom-textarea {
      width: 880px;
      height: 207px;
      background-color: #ebebeb;
    }
  }
  
  .block-wrapper-buttons-2 {
    margin-top: 62px;
    padding-top: 50px;
    padding-left: 56px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 71px;
    width: 880px;

    .save-print-button {
      text-align: center;
      display: inline;
      padding-left: 50px;

      button {
        width: 292px;
        height: 60px;
        background-color: $dark-slate-blue;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }

    .save-run-button {
      text-align: center;
      display: inline;

      button {
        width: 292px;
        height: 60px;
        background-color: $blood-orange;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }
  }

  #block-wrapper-buttons {
    padding-top: 50px;
    padding-left: 56px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 71px;
    width: 880px;

    #back-button {
      text-align: center;
      display: inline;

      button {
        width: 115px;
        height: 60px;
        background-color: $dark-slate-blue;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }

    #cancel-button {
      text-align: center;
      display: inline;
      padding-left: 118px;

      button {
        width: 292px;
        height: 60px;
        background-color: $pinkish-grey;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }

    #finish-button {
      text-align: center;
      display: inline;
      padding-left: 57px;

      button {
        width: 292px;
        height: 60px;
        background-color: $blood-orange;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }
  }
}

.new-survey-tooltip-block {
  background-color: $night-blue;

  .tooltip-title {
    width: 100%;
    height: 43px;
    font-family: BebasNeue;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #ffffff;
    padding-left: 35px;
  }

  .tooltip-text {
    width: 360px;
    height: 323px;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #ffffff;
    padding-top: 30px;
    padding-left: 35px;
  }
  .tooltip-text-title {
    color: $blood-orange;
    font-weight: bold;
  }
}

.ns-page {
  display: flex;
}

@media (max-width: 768px) {
  .New-report-page .ns-page {
    padding: 15px;
    background: #092143;
    min-height: initial;
    height: initial;
    flex-direction: column;

    #new-survey-wrapper {
      background: white;
      padding: 15px;
      margin: 0;
      width: 100%;

      #page-title {
        padding: 0;
      }

      #steps-title,
      .container {
        display: none;
      }

      #block-wrapper {
        padding-left: 0;

        #block-title {
          font-size: 20px;
          margin-bottom: 15px;
        }

        .select-numbers-1 {
          width: 100%;
        }
      }

      .date-range-block {
        #inner-block {
          padding-left: 0;
        }
      }

      #block-wrapper #block-line {
        width: 100%;
      }

      #block-wrapper-buttons,
      .block-wrapper-buttons-2 {
        width: 100%;
        flex-direction: column;
        margin-bottom: 30px;
        padding: 0;
        margin-top: 30px;

        #back-button,
        #cancel-button,
        #finish-button,
        .save-print-button,
        .save-run-button {
          padding: 0;

          button {
            width: 100%;
            margin: 10px 0;
          }
        }
      }
    }

    .new-survey-tooltip-block {
      padding-top: 50px;
    }
  }
}
