@import 'styles/colors.scss';

* focus {
  outline: 0;
}

#wrapper {
  width: 1280px;
  min-height: 670px;

  #form-container {
    width: 640px;
    height: 100%;
    float: left;
    background-color: #fff;
    font-family: BebasNeue;

    .row {
      margin: 0;
    }

    #change-pwd-wrapper,
    #subscription-wrapper {
      form {
        & > .form-group:first-child {
          margin-top: 47px;
        }
      }

      .form-group {
        margin-left: 60px;
        margin-right: 60px;
      }

      .help-block {
        margin: 0;
      }

      label {
        width: 100%;
        font-family: BebasNeue;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        letter-spacing: 2.29px;
        color: #373a49;
      }

      input {
        background-color: #efefef;
        font-family: OpenSans;
        border-radius: 0;
        border: none;
        height: 65px;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.28;
        letter-spacing: normal;
        color: #373a49;

        &:focus {
          outline: 0;
          box-shadow: none;
          -webkit-box-shadow: none;
        }
      }

      .title {
        width: 100%;
        height: 45px;
        font-family: BebasNeue;
        font-size: 40px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #050505;
        margin-top: 69px;
      }

      #change-pwd-button {
        text-align: center;
        margin-bottom: 50px;

        button {
          width: 292px;
          height: 60px;
          background-color: $blood-orange;
          font-family: BebasNeue;
          font-size: 22px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 3.14px;
          text-align: center;
          color: #ffffff;
        }
      }
    }

    #change-pwd-wrapper #change-pwd-button {
      margin-top: 96px;
    }

    .toggle {
      width: 320px;
      height: 90px;
      float: left;
      text-align: center;
      vertical-align: middle;
      font-family: BebasNeue;
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 90px;
      letter-spacing: normal;
      background-color: #1d3d69;
      color: #91add3;
      border: none;

      &:focus {
        outline: 0;
      }

      .icon-wrapper {
        margin-left: 8px;
      }
    }

    .toggle.active {
      background-color: #fff;
      color: #000000;
    }
  }

  #card-container {
    position: relative;
    width: 640px;
    height: 100%;
    float: left;

    // TODO: Remove with real card
    // background: url('../../images/sample.png') no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #061832;
  }

  #card-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;

    .slider-control-centerleft {
      display: none;
    }

    .slider-control-centerright {
      display: none;
    }

    .slider {
      height: 100% !important;
    }

    .paging-item {
      span {
        background-color: #3f4a7d !important;
      }

      button:focus {
        outline: 0;
      }
    }

    .paging-item.active {
      span {
        background-color: lighen(#3f4a7d, 40%) !important;
      }
    }

    .image-container {
      position: relative;
      margin-top: 45px;
      width: 525px;
      height: 300px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title-container {
      height: 45px;
      margin-top: 45px;
      margin-left: 60px;
      font-family: BebasNeue;
      font-size: 40px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.7;
      letter-spacing: normal;
      color: #fff;
      span {
        color: #f7941d;
      }
    }

    .text-container {
      margin: 0 60px;
      margin-top: 45px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
}

@media (max-width: 768px) {
  .Change-pwd-page {
    background-color: #092143;
    padding: 0 25px;

    #wrapper {
      width: 100%;
      height: 100%;

      #form-container {
        width: 100%;
        height: 100%;
        float: none;

        #change-pwd-wrapper,
        #subscription-wrapper {
          .form-group {
            margin-left: 30px;
            margin-right: 30px;
          }
        }

        .toggle {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 100px;
          font-size: 20px;
          line-height: 22px;
          width: 50%;
          height: 60px;
          padding: 0 20px;
        }
      }

      #card-container {
        display: none;
      }
    }
  }
}
