.edit-user-page {
  display: flex;
  h2 {
    font-size: 1.5em;
  }

  img {
    margin-right: 10px;
  }

  section {
    margin: 3em auto;

    &:first-child {
      margin-top: 0;
    }

    &.centered {
      text-align: center;
    }
  }

  form {
    margin-bottom: 1em;

    input {
      outline: none;
      border-bottom: 1px dotted #999;
    }
  }

  span.at-prefix {
    color: black;
    margin-left: 0.4em;
  }

  .SearchWrapper {
    align-items: center;
    justify-content: center;
    margin: 30px 0 50px;
  }
}

@media (max-width: 768px) {
  .edit-user-page {
    flex-direction: column;
    padding: 15px;
    margin: 0;
    background: #092143;
  }
}
