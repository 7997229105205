@import 'styles/colors.scss';

#send-notice-modal {
  min-height: 400px;
  background-color: #ffffff;
  margin: 0 20px;

  label {
    display: flex;

    & > * {
      margin-left: 5px;
    }
  }

  h2 {
    object-fit: contain;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 28px;
    letter-spacing: normal;
    color: #000000;
    margin-left: 58px;
  }

  .template-button-left {
    text-align: center;
    width: 292px;
    height: 60px;
    float: left;
    margin-right: 30px;
    padding-top: 34px;
    margin-left: 300px;
  }

  .template-button-right {
    text-align: center;
    width: 292px;
    height: 60px;
    float: left;
    padding-top: 34px;
  }

  button {
    width: 292px;
    height: 60px;
    font-family: BebasNeue;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.14px;
    text-align: center;
    color: #ffffff;
    border: 0;
    border-radius: 0;
  }

  .blue-button {
    background-color: $dark-slate-blue;
  }

  .orange-button {
    background-color: $blood-orange;
  }
}

.send-notice-modal-line {
  width: 100%;
  margin-top: 0;
  border-top: solid 1px #979797;
}
