$blood-orange: #fe5200;
$greyish-brown: #4a4a4a;
$warm-grey: #979797;
$pinkish-grey: #c0c0c0;
$dark-slate-blue: #1d3d69;
$night-blue: #061832;
$grey-blue: #8192bf;
$light-grey: #ebebeb;
$header-black: #092143;
$white-grey: #dedede;
$very-light-blue: #eaf1ff;
$emerald-green: #04822b;
$perrywinkle: #7ca3da;
$charcoal-grey: #373a49;

// tips
$tip-success: #95bd8c;
$tip-notify: #adcee6;
$tip-error: #ff8181;
$tip-error-bg: #ffeeee;

// $white-grey: #efefef;
