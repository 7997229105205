#contact-form-text-container {
  text-align: center;
  padding: 0 20px;
}

#contact-form-wrapper {
  form {
    overflow-y: auto;
    height: 700px;
  }
  .radio-cmp {
    display: flex;
    align-items: center;

    input {
      line-height: 28px;
      height: 28px;
    }
  }
  .form-group {
    &.required label .name::after {
      content: ' *';
    }

    &.select {
      select {
        width: 100%;
      }
    }
    label {
      width: 100%;
      input[type='radio'] {
        line-height: 21px;
        height: 21px;
        margin: 0;
        margin-left: 5px;
      }
    }
  }
  #login-button {
    margin-top: 40px;
  }
}
