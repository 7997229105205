footer {
  display: flex;
  justify-content: space-between;
  padding: 3em 0;
  margin-top: 20px;

  .contact-us {
    margin-top: -2em;
    color: #fe5200;
    text-transform: uppercase;
    cursor: pointer;
  }

  #contact-form-wrapper form {
    height: auto;
    max-height: 65vh;

    min-width: 300px;
    max-width: 600px;
  }

  a {
    color: #41addd;

    &:hover {
      color: #6cc0e5;
    }
  }
}
