@import '../../styles/colors.scss';

.pageHeader {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 40px;

  .headerLeftSide {
    flex-grow: 1;
  }

  .headerRightSide {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: -40px;

    .headerButton {
      //TODO: hide while have no link

      letter-spacing: 3.14px;
      width: 301px;
      height: 60px;
      font-family: BebasNeue;
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 3.14px;
      text-align: center;
      color: #ffffff;
    }
  }
}

.pageTitle {
  font-family: OpenSans;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: $blood-orange;
}

.pageDescription {
  font-family: OpenSans;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  padding-top: 5px;
}

.reportContainer {
  display: flex;
  border: solid 1px $warm-grey;
  justify-content: space-between;
  margin-bottom: 10px;

  .leftSide {
    padding-bottom: 38px;
    padding-top: 42px;
    padding-left: 42px;

    .reportTitle {
      .reportTitleText {
        font-family: BebasNeue;
        font-size: 42px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $dark-slate-blue;
      }

      .reportTitleDescription {
        font-family: OpenSans;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $greyish-brown;
      }
    }

    .iconsBlock {
      display: flex;
      // justify-content: space-between;
      padding-top: 30px;
      margin-right: 30px;

      .iconTimeBlock {
        display: flex;
        margin-right: 20px;

        .iconTimeLabel {
          width: 78px;
          height: 56px;
          font-family: OpenSans;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $blood-orange;
        }

        .iconTimeImg {
          flex: 0 0 48px;
        }
      }

      .iconDataBlock {
        display: flex;

        .iconDataLabel {
          margin-right: 4px;
          width: 62px;
          height: 37px;
          font-family: OpenSans;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: $blood-orange;
        }

        .iconDataImg {
          flex: 0 0 48px;
        }
      }
    }

    .buttonBlock {
      padding-top: 32px;

      .button {
        width: 292px;
        height: 60px;
        background-color: $blood-orange;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }
  }

  .rightSide {
    padding-top: 53px;
    padding-bottom: 38px;
    padding-right: 82px;

    .provides {
      object-fit: contain;
      font-family: OpenSans;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-transform: uppercase;
      color: $dark-slate-blue;
      padding-top: 42px;
      padding-bottom: 12px;
      padding-right: 60px;
    }

    .listsBlock {
      display: flex;

      .usesBlock {
        .usesTitle {
          font-family: OpenSans;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: $greyish-brown;
        }

        .usesItem {
          font-family: OpenSans;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: $greyish-brown;
        }
      }

      .dataSetsBlock {
        padding-left: 40px;

        .dataSetsTitle {
          font-family: OpenSans;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: $greyish-brown;
        }

        .dataItem {
          font-family: OpenSans;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          color: $greyish-brown;
        }
      }
    }
  }

  .reportHeader {
    justify-content: space-between;
    display: inline-flex;
    width: 100%;

    .provides {
      padding-right: 179px;
      align-self: flex-end;
      font-family: OpenSans;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $dark-slate-blue;
    }
  }
}

.buttonBlock {
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  .btnBack {
    width: 120px;
    height: 60px;
    background-color: $pinkish-grey;
    font-family: BebasNeue;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.14px;
    text-align: center;
    color: #ffffff;
  }

  .btnCustomComparison,
  .btnQuickReport {
    width: 280px;
    height: 60px;
    background-color: $dark-slate-blue;
    font-family: BebasNeue;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.14px;
    text-align: center;
    color: #ffffff;
  }

  .btnPlcReport {
    width: 280px;
    height: 60px;
    background-color: $dark-slate-blue;
    font-family: BebasNeue;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.14px;
    text-align: center;
    color: #ffffff;
  }
}

@media (max-width: 768px) {
  .pageHeader {
    flex-direction: column;
  }

  .headerRightSide {
    margin: 0 !important;
    justify-content: flex-start;
    flex: 1;

    a {
      flex: 1;

      .headerButton {
        margin: 30px 0 0 !important;
        width: 100% !important;
      }
    }
  }

  .pageTitle {
    margin-bottom: 10px;
  }

  .reportContainer {
    flex-direction: column;
    padding: 30px;

    .leftSide {
      padding: 0;

      .buttonBlock {
        width: 100%;

        .button {
          margin: 0;
          width: 100%;
        }
      }
    }

    .rightSide {
      padding: 0;

      .listsBlock {
        flex-direction: column;
      }

      .dataSetsBlock {
        padding: 0 !important;
      }
    }
  }

  .buttonBlock {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 20px;

    a {
      flex: 1;
    }

    .btnCustomComparison,
    .btnQuickReport,
    .btnPlcReport,
    .btnBack {
      margin: 15px 0;
      width: 100%;
    }
  }
}