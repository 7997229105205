.app-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.Toastify__toast-container.Toastify__toast-container--top-right.pr-10 .Toastify__toast-body {
  padding-right: 10px;
}
