@import 'styles/colors.scss';

.modalTitle {
  font-size: 21px;
}

.modalButtons {
  margin: 120px 0px 0;
  display: flex;

  div {
    flex: 1;
    width: initial;

    &:first-child {
      margin-right: 25px;
    }
  }
}
  