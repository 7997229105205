@import '../../styles/colors.scss';

* focus {
  outline: 0;
}

#printWrapper {
  left: -10000px;
  position: absolute;
  top: 0;
}

.new-survey-wrapper {
  width: 998px;
  height: 100%;

  .blockWrapper {
    display: flex;
    // margin-top: 30px;
    // margin-bottom: 30px;
    align-items: center;
  }

  .block-line {
    display: flex;
    justify-content: left;
    width: 850px;
    height: 0px;
    border: solid 1px $warm-grey;
  }

  .checkboxWrapper {
    flex: none;
    margin-top: 13px;
    margin-right: 14px;
    display: contents;
  }
  .labelWrapper {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 1.33;
    color: $greyish-brown;
    padding-left: 10px;
  }

  .labelModule {
    display: flex;
    padding-left: 10px;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 3.57;
    letter-spacing: normal;
    color: $blood-orange;
  }

  .container {
    width: 760px;
    margin: 50px auto;
  }
  .progressbar {
    counter-reset: step;
  }
  .progressbar li {
    width: 154px;
    height: 27px;
    list-style-type: none;
    float: left;
    position: relative;
    font-family: OpenSans;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 27px;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
  }
  .progressbar li.actived {
    width: 154px;
    height: 27px;
    list-style-type: none;
    float: left;
    position: relative;
    font-family: OpenSans;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 27px;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: $blood-orange;
  }
  .progressbar li.active {
    width: 154px;
    height: 27px;
    list-style-type: none;
    float: left;
    position: relative;
    font-family: OpenSans;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 27px;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: $blood-orange;
  }
  .progressbar li:before {
    width: 24px;
    height: 24px;
    content: '';
    display: block;
    font-size: 24px;
    margin: 0 auto 0px auto;
    border-radius: 50%;
    line-height: 24px;
    background-color: #7d7d7d;
  }
  .progressbar li:after {
    width: 110px;
    height: 1px;
    border: 1px solid #7d7d7d;
    content: '';
    position: absolute;
    top: 12px;
    left: -36%;
    z-index: 1;
  }
  .progressbar li.actived:before {
    width: 24px;
    height: 24px;
    content: '';
    display: block;
    font-size: 24px;
    margin: 0 auto 0px auto;
    line-height: 24px;
    background-color: $blood-orange;
  }
  .progressbar li.actived:after {
    width: 110px;
    height: 1px;
    border: 1px solid $blood-orange;
    content: '';
    position: absolute;
    top: 12px;
    left: -36%;
    z-index: 1;
  }
  .progressbar li.active:before {
    width: 24px;
    height: 24px;
    content: '•';
    display: block;
    font-size: 42px;
    border-radius: 50%;
    line-height: 21px;
    border: 1px solid $blood-orange;
    background-color: $blood-orange;
    color: #ffffff;
    padding-left: 2px;
  }
  .progressbar li.active:after {
    width: 110px;
    height: 1px;
    border: 1px solid $blood-orange;
    content: '';
    position: absolute;
    top: 12px;
    left: -36%;
    z-index: 1;
  }
  .progressbar li:first-child:after {
    content: none;
  }
  .progressbar li.active {
    color: $blood-orange;
  }
  .progressbar li.active:before {
    border-color: $blood-orange;
  }
  .progressbar li.active + li:after {
    background-color: #cacaca;
  }

  #page-title {
    padding-left: 57px;
    padding-top: 42px;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #000000;
  }

  #steps-title {
    padding-top: 14px;
    display: flex;
    justify-content: center;
    font-family: OpenSans;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $blood-orange;
  }

  #block-title {
    display: flex;
    padding-top: 51px;
    font-family: OpenSans;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $greyish-brown;
  }

  .block-title--text {
    flex: 1;
    display: flex;
  }

  .error-message {
    flex: 1;
    display: inline-block;
    color: #a94442;
    font-size: 15px;
    place-self: center;
    text-align: right;
    margin-right: 20px;
  }

  #block-wrapper {
    padding-left: 56px;
    padding-right: 56px;

    .date-time--error {
      border: 3px solid $blood-orange;
    }

    #block-line {
      display: flex;
      justify-content: left;
      margin-left: -0%;
      width: 880px;
      height: 0px;
      border: solid 1px $warm-grey;
    }

    #inner-block {
      display: flex;
      flex-direction: column;
      margin-right: 25%;
      min-width: 130px;

      .inner-item {
        font-family: OpenSans;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 3.57;
        letter-spacing: normal;
      }

      .room-select {
        margin-left: -10px;
      }
    }

    #inner-block-line {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .custom-select {
        margin-left: 10px;
        align-self: center;
      }

      .select-numbers {
        width: 91px;
        height: 38px;
        border-radius: 5px;
        background-color: #ebebeb;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .label-numbers {
        width: 174px;
        height: 37px;
        font-family: OpenSans;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 3.57;
        letter-spacing: normal;
        color: $greyish-brown;
        padding-left: 10px;
      }
    }

    #bottom-textarea {
      width: 880px;
      height: 207px;
      background-color: #ebebeb;
    }
  }

  #block-wrapper-buttons {
    margin-top: 62px;
    padding-left: 56px;
    padding-right: 56px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 71px;

    & > div {
      margin-left: 50px;

      &:first-child {
        margin: 0;
      }
    }

    #back-button {
      text-align: center;
      display: inline;

      button {
        width: 115px;
        height: 60px;
        background-color: $dark-slate-blue;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }

    #cancel-button {
      text-align: center;
      display: inline;
      padding-left: 118px;

      button {
        width: 292px;
        height: 60px;
        background-color: $pinkish-grey;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }

    #finish-button {
      text-align: center;
      display: inline;
      padding-left: 57px;

      button {
        width: 292px;
        height: 60px;
        background-color: $blood-orange;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }
  }

  #block-wrapper-template-buttons {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 71px;
    width: 100%;
    height: 60px;
    flex-direction: column;

    .template-button {
      text-align: center;
      display: flex;
      justify-content: center;
      padding-bottom: 41px;

      button {
        width: 292px;
        height: 60px;
        background-color: $dark-slate-blue;
        font-family: BebasNeue;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 3.14px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 0;
      }
    }
  }
}

.new-survey-tooltip-block {
  background-color: $night-blue;
  position: relative;

  .tooltip-title {
    width: 100%;
    font-family: BebasNeue;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #ffffff;
    padding-left: 35px;
  }

  .tooltip-text {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #ffffff;
    padding-top: 30px;
    padding-left: 35px;
    padding-right: 20px;
  }
  .tooltip-text-title {
    color: $blood-orange;
    font-weight: bold;
  }
}

.ns-page {
  position: relative;
  display: flex;

  .new-survey-wrapper {
    width: 998px;
    height: 100%;
  }

  .block-wrapper {
    padding-left: 56px;
    padding-right: 56px;

    .block-title {
      padding-top: 51px;
      font-family: OpenSans;
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $greyish-brown;
    }

    .block-line {
      display: flex;
      justify-content: left;
      width: 100%;
      height: 0px;
      border: solid 1px $warm-grey;
    }

    .inner-block-line {
      display: flex;
      justify-content: flex-start;
      flex-flow: wrap;

      .inner-block {
        display: flex;
        flex-direction: row;
        width: calc((100% - 40px) / 3);
        margin: 0 0 15px;

        .inner-block-item {
          display: flex;
          align-items: center;

          .select-numbers {
            width: 91px;
            height: 38px;
            border-radius: 5px;
            background-color: #ebebeb;
            align-items: center;
            display: flex;
            justify-content: center;
          }

          .label-numbers {
            width: 174px;
            font-family: OpenSans;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 3.57;
            letter-spacing: normal;
            color: $greyish-brown;
            padding-left: 10px;
          }
        }
      }

      .inner-block:nth-child(3n-1) {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}

@media (max-width: 768px) {
  .New-survey-page .ns-page {
    background: #092143;
    padding: 0 15px;

    .new-survey-wrapper {
      width: 100%;
      padding: 40px 15px 0;
      background: white;

      #page-title {
        padding: 0;
      }

      .container {
        display: none;
      }

      #inner-block-line {
        #inner-block:first-child {
          flex: 0 0 130px;
          width: 130px;
          margin-right: 20px;
        }
        #inner-block:last-child {
          margin: 0;
          flex: 1;

          .room-select {
            width: 100%;
          }

          .named-select-wrapper {
            min-width: 100%;
          }
        }
      }

      #block-wrapper {
        padding: 0;

        #bottom-textarea {
          width: 100%;
        }
      }

      #block-title {
        display: flex;
      }

      .block-title--text {
        flex: 1;
        margin-right: 10px;
      }

      #block-wrapper-buttons {
        flex-direction: column-reverse;
        padding: 0 25px;
        margin-bottom: 40px;

        div {
          margin: 0;
        }

        div:first-child {
          margin: 20px 0 0;
        }

        div:last-child {
          margin: 0 0 20px;
        }
      }

      .new-survey-tooltip-block {
        display: none;
      }
    }
  }
}
