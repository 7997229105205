.mobile-menu {
  display: none;
}

.header {
  margin: 20px 0;
  align-self: center;
  width: 100%;

  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  #logo-block {
    display: flex;
    flex-direction: row;
    padding-bottom: 70px;
    align-content: center;
  }

  #tabs-block {
    display: flex;
    flex: 1;
    padding-top: 10px;

    .nav-bar {
      margin-left: 50px;
    }
  }

  #logo {
    width: 210px;
    height: 80px;
    position: relative;
    top: 20px;
    background: url('../../images/logo-red.png');
    background-repeat: no-repeat;
    background-size: 210px 80px;
  }

  #powered-by {
    display: block;
    float: left;
    height: 80px;
    color: #fff;
    padding: 10px 20px;
    font-size: 30px;
    font-weight: 900;
  }

  #stamp {
    width: 84px;
    height: 80px;
    display: block;
    float: left;
    background: url('../../images/logo-circle.png');
    background-repeat: no-repeat;
    background-size: 84px 80px;
  }

  .nav-bar {
    display: flex;
    align-items: center;

    .icon-wrapper {
      margin-right: 4px;
    }

    .router-link {
      display: inline-flex;
      padding: 0.25em 1em;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      -webkit-touch-callout: none;
      user-select: none;
      cursor: pointer;
      outline: 0;
      font-family: BebasNeue;
      font-size: 18px;
      color: #c0ccffb3;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.1px;

      &:active {
        color: darken(#c0ccffb3, 10%);
      }
    }

    .router-link-inner {
      display: inline-flex;
      padding: 0.25em 1em;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      -webkit-touch-callout: none;
      user-select: none;
      cursor: pointer;
      outline: 0;
      font-family: BebasNeue;
      font-size: 18px;
      color: #092143;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.1px;

      &:active {
        color: darken(#c0ccffb3, 10%);
      }
    }

    .router-link ul {
      list-style: none;
      display: none;
      margin: 0;
      padding: 0;
    }

    &.nav-bar-mobile {
      .router-link {
        display: block;

        .open-menu-item {
          display: block;
        }
      }
    }

    &.nav-bar-desktop {
      .router-link ul {
        position: absolute;
        z-index: 9999;
      }

      .router-link:hover ul {
        display: block;
        background-color: #d8d8d8;
        color: #092143;
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0 30px;

    #tabs-block {
      display: none;
    }

    #logo-block {
      padding-bottom: 10px;
    }

    #logo {
      width: 104px;
      height: 40px;
      background-size: 104px auto;
    }

    #stamp {
      width: 44px;
      height: 61px;
      background-size: 44px auto;
      background-position: center;
      margin-left: 17px;
    }

    .mobile-menu {
      $padding: 15px;
      display: block;
      position: absolute;
      right: $padding;
      top: $padding;
      z-index: 9;

      &.open {
        background-color: #d8d8d8;
        left: $padding;

        .menu-icon-wrapper {
          color: #092143;
        }

        #tabs-block .nav-bar {
          display: flex;
          flex-direction: column;
          margin-left: 80px;
          text-align: left;
          align-items: flex-start;
          margin-top: 70px;
          padding-bottom: 30px;
          margin-left: 50px;
          margin-right: 50px;
        }

        .router-link {
          text-decoration: none;
          font-size: 24px;
          line-height: 48px;
          letter-spacing: 1.47px;
          color: #092143;
          width: 100%;

          ul {
            background: #092143;
            width: 100%;
          }

          .router-link-inner {
            color: #8999c6;
          }
        }

        .icon-wrapper {
          margin-right: 10px;
          width: 25px;
          text-align: center;
        }
      }

      #tabs-block {
        display: block;
      }

      .menu-icon-wrapper {
        font-size: 24px;
        color: #8999c6;
        position: absolute;
        top: 23px;
        right: 20px;
      }
    }
  }
}
